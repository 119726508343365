.rental-service {
    $text-color: #21333d;
    $text-color-2: #47463f;
    $link-color: #a38966;
    $background-1: #fffaf4;
    $background-2: #fbf4ea;
    background-color: $background-1;
    font-weight: 400;
    color: $text-color;
    h2 {
        font-size: 36px;
    }

    a {
        text-decoration: none;
    }

    .social-links {
        a {
            color: $text-color;
            font-size: 20px;
        }
    }

    .overlay-forms {
        .form-control {
            border: 0;
        }
        .btn-primary {
            width: 100%;
        }
    }

    .form-wrapper,
    #book-free-quote {
        .daterangepicker {
            border: 0;
            border-radius: 2px;
            box-shadow:
                rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
                rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
            padding: 8px;
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
            table {
                thead {
                    tr {
                        .month {
                            font-size: 14px;
                            color: $text-color;
                            font-weight: 600;
                        }
                        &:first-child {
                            border-bottom: 8px solid transparent;
                            position: relative;
                        }
                        &:last-child {
                            th {
                                font-size: 14px;
                                color: $text-color-2;
                                font-weight: 500;
                            }
                        }
                        th {
                            border: none;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            min-width: 32px;
                            min-height: 32px;
                            width: 34px;
                            height: 34px;
                            color: $text-color;
                            border-radius: 50%;
                            border: none;
                            font-weight: 500;
                            &.active {
                                background-color: #ffe3cb;
                                border-radius: 50%;
                                position: relative;
                                color: $text-color;
                                font-weight: 600;
                            }
                            &.in-range {
                                background-color: #ffe3cb;
                                color: $text-color;
                            }
                            &.off {
                                background-color: #fff;
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }
    }

    .link {
        color: #7e6f59;
        &:hover {
            color: $text-color;
        }
    }
    .contact-wrapper {
        .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }
    .form-control {
        color: $text-color;
        background-color: #fff;
        border: 1px solid #eeeeee;
        padding: 12px;
        text-align: left;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        border-radius: 2px;
        &:focus {
            box-shadow: none;
        }
        &::placeholder {
            color: $text-color;
            opacity: 1;
        }
        &::-ms-input-placeholder {
            color: $text-color;
        }
        &::-webkit-file-upload-button,
        &::file-selector-button {
            background-color: #ffe3cb;
            color: $text-color;
            padding: 12px;
            margin-top: -12px;
            margin-bottom: -12px;
        }
    }
    .btn-primary {
        background-color: #ffe3cb;
        border: 0;
        border-radius: 2px;
        color: $text-color;
        font-weight: 500;
        padding: 10px 20px;
        font-size: 14px;
        &:focus {
            box-shadow: none;
        }
        &:active {
            background-color: #ffe3cb;
            color: currentColor;
        }
    }
    .dropdown-menu {
        border-radius: 0.75rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        min-width: 16rem;
    }
    .rental-statement {
        padding: 12px 0;
        background-color: $background-2;
        h6 {
            font-size: 1.15rem;
            font-weight: 700;
        }
    }
    .carousel-item {
        background-color: black;
        .img-fluid {
            position: relative;
            opacity: 0.8;
        }
    }
    .navbar {
        .navbar-brand {
            font-size: 20px;
            font-weight: 600;
            padding: 0;
            color: $text-color;
        }
        .nav-link {
            color: $text-color-2;
            font-weight: 500;
            &:hover {
                color: $link-color;
            }
        }
        .dropdown-item {
            color: $text-color-2;
            &:hover,
            &:active {
                background-color: transparent;
                color: $link-color;
            }
        }
    }
    .rentals-bg {
        background-color: $background-2;
        padding: 80px 0;
    }
    .hero-wrapper {
        .overlay-text {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
            left: 7em;
            z-index: 2;
            h1 {
                font-size: 3.5rem;
            }
        }
        .content-wrapper {
            max-width: 900px;
            margin: auto;
            margin-top: 60px;
            margin-bottom: 60px;
            p {
                font-size: 18px;
                margin-top: 2rem;
            }
        }
    }
    .rental-features {
        // background-color: $background-2;
        padding: 80px 0;
        .feature-heading {
            font-size: 45px;
        }
        .feature-box {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .icon-box {
                min-height: 160px;
                .text-icon-container {
                    display: flex;
                    .text-icon-box {
                        flex: 0 0 75px;
                        -webkit-padding-start: 0;
                        padding-inline-start: 0;
                        -webkit-padding-end: 15px;
                        padding-inline-end: 15px;
                    }
                }
            }
        }
        .mission-statement {
            font-style: italic;
            position: relative;
            font-size: 18px;
            &::before {
                color: #f5cca8;
                content: "\f10d";
                font-family: "Font Awesome 6 Free";
                font-size: 30px;
                font-weight: 900;
                left: 0;
                position: absolute;
                top: -40px;
            }
        }
    }
    .rental-categories {
        margin: 80px 0;
        .categories {
            margin-top: 2.5rem;
            ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li {
                    width: calc(16.666% - 28px);
                    text-align: center;
                    margin-bottom: 25px;
                    a {
                        color: $text-color;
                        display: block;
                        &:hover {
                            color: $link-color;
                        }
                    }
                    h4 {
                        margin-top: 12px;
                    }
                }
            }
        }
    }
    .rental-products {
        margin: 80px 0;
        .products {
            margin-top: 40px;
            ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li {
                    width: calc(25% - 20px);
                    margin-bottom: 25px;
                    .img-section {
                        height: 250px;
                        position: relative;
                        overflow: hidden;
                        padding-bottom: 100%;
                        border-radius: 8px;
                        .img-fluid {
                            min-height: 100%;
                            min-width: 100%;
                            position: absolute;
                            inset: 0px;
                            max-width: 100%;
                            object-fit: cover;
                        }
                    }
                    a {
                        color: $text-color;
                        display: block;
                        &:hover {
                            color: $link-color;
                        }
                    }
                    h4 {
                        margin-top: 12px;
                    }
                }
            }
        }
    }
    .rental-background {
        position: relative;
        @media screen and (min-width: 768px) {
            min-height: 28rem;
        }
        .img-section {
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            overflow: hidden;
            .img-fluid {
                object-position: 50% 50%;
                display: block;
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
        }
        .background-text {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            h1 {
                color: #fff;
                text-transform: capitalize;
            }
            .custom-quote {
                font-size: 22px;
                background: #ffe3cb;
                color: darkslategrey;
                font-style: italic;
                width: fit-content;
                padding-left: 10px;
                font-weight: 500;
                padding-right: 10px;
            }
        }
        &.top {
            .img-section {
                &::after {
                    position: absolute;
                    content: "";
                    background-color: rgba(0, 0, 0, 0.4);
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
    .rental-services {
        margin: 80px 0;
        &.rentals-bg {
            margin-top: 0;
        }
        .services {
            margin-top: 40px;
            .service-card {
                position: relative;
                margin-bottom: 1.5rem;
                .img-section {
                    height: 250px;
                    position: relative;
                    overflow: hidden;
                    padding-bottom: 100%;
                    .img-fluid {
                        min-height: 100%;
                        min-width: 100%;
                        position: absolute;
                        inset: 0px;
                        max-width: 100%;
                        object-fit: cover;
                    }
                }
                h4 {
                    margin-top: 12px;
                    text-transform: uppercase;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    color: #fff;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 26px;
                    max-width: 85%;
                    margin: auto;
                }
                &::after {
                    overflow: hidden;
                    -webkit-mask-image: -webkit-radial-gradient(white, black);
                    mask-image: radial-gradient(white, black);
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.4);
                }
            }
        }
        .services-footer {
            margin-top: 2rem;
        }
        &.only-rentals {
            .services {
                .service-card {
                    display: block;
                    img {
                        transition: all 0.4s ease;
                    }
                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    .rental-footer {
        background-color: $background-2;
        padding-top: 60px;
        padding-bottom: 15px;
        .footer-heading {
            margin-bottom: 1rem;
        }
        .my-cart-icon {
            position: fixed;
            display: none;
            background-color: #ffe3cb;
            bottom: 20px;
            right: 50px;
            transition: -webkit-transform 0.2s;
            transition: transform 0.2s;
            transition:
            transform 0.2s,
            -webkit-transform 0.2s;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            will-change: transform;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 1rem;
            border-radius: 30px;
            color: $text-color;
            gap: 10px;
            cursor: pointer;
            .badge-notify {
                font-weight: 600;
                font-size: 14px;
                line-height: 1;
            }
        }
        .footer-links {
            p {
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
                a {
                    color: inherit;
                }
            }
        }
    }
    .wd-heading {
        .sub-title {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 500;
        }
        .title {
            font-size: 46px;
        }
    }
    .rental-gallery {
        background-color: $background-2;
        padding: 60px 0;
        margin: 80px 0;
        &.only-gallery {
            background-color: transparent;
            margin: 0;
            .gallery-card {
                overflow: hidden;
                position: relative;
                &:before {
                    content: "";
                    display: block;
                    padding-top: 66.67%;
                }
                .img-fluid {
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    margin: auto;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .wd-heading {
            margin-bottom: 40px;
        }
        .gallery-footer {
            margin-top: 2rem;
        }
    }
    .rental-stats {
        margin: 80px 0;
        .stat-text {
            h2 {
                margin-bottom: 1rem;
            }
            p {
                font-weight: 500;
                margin-bottom: 0;
            }
        }
        .stat-card {
            h3 {
                margin-top: 10px;
            }
        }
    }
    .rental-appointment {
        margin: 40px 0;
        .border-container {
            border-top: 1px solid #eeeeee;
            padding-top: 80px;
        }
    }
    .navbar-toggler {
        height: 35px;
        width: 35px;
        background-color: $background-2;
        border-radius: 50%;
        padding: 0;
        font-size: 16px;
        &:focus {
            box-shadow: none;
        }
    }
    .thank-you-container {
        padding: 100px 0 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        .stat-text {
            position: relative;
            .team-name {
                font-weight: 500;
            }
        }
        .back-button {
            a {
                color: $text-color;
            }
        }
    }
    .membership {
        padding: 50px 0;
        margin-top: 60px;
        border-top: 1px solid #eeeeee;
        .membership-wrapper {
            padding-bottom: 0;
        }
        h3 {
            margin-bottom: 3rem;
        }
    }

    // 1-may

    .main-space {
        padding: 60px 0px;
    }
    .pb-60 {
        padding-bottom: 60px;
    }
    .img-card {
        img {
            width: 100%;
            height: 400px;
            background-color: #ffe3cb;
            object-fit: scale-down;
        }
        &::after {
            content: "";
            position: absolute;
            width: 20px;
            top: 20px;
            bottom: 20px;
            background-color: $text-color;
        }
    }
    .about-service {
        .stat-text {
            padding-left: 50px;
        }
    }
    .swipe-slider {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .swipe-card {
        background-color: $background-2;
        padding: 20px 0 10px;
        display: grid;
        place-content: center;
        text-align: center;
        border: 1px solid transparent;
        height: 250px;
        transition: all 0.4s ease;
        &:hover {
            // border: 1px solid #000;
            box-shadow: 0px 2px 6px #00000042;
            .icon-div {
                transform: translateY(-57px);
                border: 1px solid #000;
                width: 140px;
                height: 140px;
                transition: all 0.4s ease;
                img {
                    width: 80%;
                    margin: auto;
                    transition: all 0.4s ease;
                }
            }
        }

        .icon-div {
            background-color: #fbf9f7;
            width: 160px;
            height: 160px;
            border-radius: 50%;
            margin: 0 auto;
            display: grid;
            place-content: center;
            border: 1px solid transparent;
            transition: all 0.4s ease;
        }
    }
    .content-box-parent {
        .content-box {
            margin-bottom: 20px;
        }
    }
    .mb-40 {
        margin-bottom: 40px;
    }
    .category-blk {
        .active {
            border: 2px solid #a38966;
            outline: none; /* Optional: removes the default focus outline */
        }
        .img-blk {
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            overflow: hidden;
            position: relative;
            transform: translateZ(0);
            -webkit-transform: translateZ(0);
            box-shadow: 0px 0px 6px #00000014;
            img {
                transition: all 0.4s ease;
            }

            .floating-price {
                position: absolute;
                border: 0px none;
                bottom: 0;
                right: 0;
                padding: 1px 10px;
                font-weight: 600;
                font-size: 14px;
                background-color: #ffe3cb;
            }
            .floating-add-to-cart {
                position: absolute;
                top: 0;
                right: 0;
                padding: 1px 10px;
                font-weight: 600;
                font-size: 14px;
                background-color: #ffe3cb;
            }
        }
        &:hover {
            .img-blk {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    .rental-tents {
        .img-blk {
            img {
                height: 300px;
                width: 100%;
                object-fit: contain;
                background-color: #fff;
            }
        }
        h4 {
            background: #ffe3cb;
            width: max-content;
            padding: 0px 10px;
            max-width: fit-content;
            text-align: center;
            margin: auto;
            margin-top: 12px;
            font-size: 20px;
        }
        .category-blk {
            margin-bottom: 2.5rem;
        }
    }
    .rental-page-wrap {
        .rental-gallery {
            margin: 0px;
        }
    }

    // 2-may
    .more-inquiry-blk {
        cursor: pointer;
        gap: 1rem;
        text-align: center;
        .inquiry-icon {
            img {
                width: 60px;
            }
        }
        &.single-col {
            margin-top: 0px;
        }
        .inquiry-icon {
            border: 2px solid #202020;
            padding: 15px;
            border-radius: 50%;
        }
    }

    .gallery-card {
        img {
            height: 200px;
            width: 100%;
            object-fit: cover;
        }
    }

    // 3-may

    .lounge-page {
        .rental-tents {
            .img-blk {
                img {
                    object-fit: scale-down;
                }
            }
        }
    }

    .charger-plate-gallery {
        .gallery-card {
            img {
                object-fit: scale-down;
            }
        }
    }

    // 6-may

    .price-tag {
        font-size: 18px;
        font-weight: 600;
        width: max-content;
        background: #ffe3cb;
        padding: 1px 10px;
        margin-top: 1.5rem;
    }

    // Event Rental Page
    .rentals-page {
        .background-text {
            h1 {
                font-size: 3.5rem;
            }
        }
    }

    // 12 June
    .reviews {
        margin-top: 80px;
        &.page {
            margin-bottom: 80px;
        }
        .wd-heading {
            margin-bottom: 40px;
        }
        .review-card {
            padding: 2rem;
            background-color: $background-2;
            position: relative;
            &::before {
                color: #f5cca8;
                content: "\f10d";
                font-family: "Font Awesome 6 Free";
                font-size: 30px;
                font-weight: 900;
                left: 30px;
                position: absolute;
                top: 10px;
            }
            p {
                padding-top: 2rem;
                font-style: italic;
                b {
                    color: #0d6efd;
                }
            }
            .rating-author {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                .rating {
                    color: #ffa534;
                }
                .author {
                    font-size: 20px;
                    font-weight: 500;
                }
            }
            &.add {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    padding: 0;
                }
                &::before {
                    display: none;
                }
            }
        }
        .testimonials {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            margin-bottom: 3rem;
            justify-content: space-between;
            .image-wrapper {
                border: 1px solid #ccc;
                display: flex;
                justify-content: center;
                overflow: hidden;
                position: relative;
                gap: 1rem;
                width: calc(100% / 2 - 12px);
                max-height: 400px;
            }
        }
    }

    // 19 June
    .city-div {
        margin-bottom: 2rem;
    }
    .cities {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;
        .city-box {
            padding: 1rem;
            background-color: $background-2;
            color: $text-color;
            font-weight: 500;
            white-space: nowrap;
        }
    }

    @media screen and (max-width: 1200px) {
        .footer-content {
            padding-left: 0 !important;
        }
        .navbar > .container {
            gap: 1rem;
        }
        // 19 June
        .cities {
            grid-template-columns: repeat(3, 1fr);
        }
        .rental-gallery {
            padding: 30px 0;
        }
        .gallery-tabs {
            button {
                flex: 1;
            }
        }
        .more-inquiry-blk {
            .inquiry-icon {
                img {
                    width: 60px;
                }
            }
            .title {
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .navbar {
            .nav-link {
                padding: 8px 6px;
            }
        }
        .rental-statement {
            h6 {
                font-size: 1rem;
            }
        }
        .rental-features {
            padding: 60px 0;
            .feature-heading {
                margin-bottom: 2rem;
            }
        }
        .rental-products {
            .products {
                ul {
                    li {
                        width: calc(50% - 28px);
                    }
                }
            }
        }
        .rental-categories {
            justify-content: space-evenly;
            .categories {
                ul {
                    justify-content: flex-start;
                    column-gap: 1.5rem;
                    li {
                        width: calc(25% - 25px);
                    }
                }
            }
        }
        .rental-background {
            min-height: 30rem;
            &.top {
                .img-section {
                    .img-fluid {
                        object-position: 50% 50%;
                    }
                }
            }
            .img-section {
                .img-fluid {
                    object-position: 0 50%;
                }
            }
        }
        .rental-tents {
            .img-blk {
                img {
                    height: 200px;
                }
            }
        }
        .wd-heading {
            text-align: center;
        }
    }
    @media screen and (max-width: 992px) {
        .navbar {
            .nav-link {
                padding: 8px 0;
            }
        }
        .contact-wrapper {
            column-gap: 1rem;
            .nav-link {
                padding-right: 0;
                padding-left: 0;
            }
        }
        .rentals-page {
            .background-text {
                h1 {
                    font-size: 2.5rem;
                }
            }
        }
        .about-service {
            .stat-text {
                padding: 20px 0px 0px 0;
            }
        }

        .cities {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media screen and (max-width: 768px) {
        .price-tag {
            width: auto;
        }
        .rental-products {
            margin: 40px 0;
            .products {
                ul {
                    li {
                        width: calc(50% - 12px);
                    }
                }
            }
        }
        .rental-categories {
            margin: 40px 0;
            .categories {
                ul {
                    li {
                        width: calc(33% - 25px);
                    }
                }
            }
        }
        .wd-heading {
            .title {
                font-size: 26px;
            }
        }
        .rental-features {
            padding: 40px 0;
            .feature-heading {
                font-size: 28px;
                margin-bottom: 2rem;
            }
            .feature-box {
                margin-bottom: 2rem;
                .icon-box {
                    min-height: auto;
                }
            }
        }
        .about-service {
            .img-card {
                img {
                    height: 350px;
                    object-fit: cover;
                }
            }
        }
        .rental-tents {
            .img-blk {
                img {
                    height: 250px;
                    object-fit: contain;
                    aspect-ratio: 1;
                }
            }
        }
        .main-space {
            padding: 30px 0px;
        }

        // 19 June
        .cities {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media screen and (max-width: 420px) {
        .navbar .dropdown-item {
            font-size: 14px;
            white-space: normal;
        }
        .navbar {
            .nav-link {
                padding: 8px 0;
            }
            .contact-wrapper {
                .nav-link {
                    padding: 0;
                }
            }
        }
        .rental-statement {
            h6 {
                font-size: 12px;
            }
        }
        .rental-products {
            .products {
                ul {
                    li {
                        width: calc(50% - 12px);
                        .img-section {
                            height: 170px;
                        }
                        h4 {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .rental-categories {
            .categories {
                ul {
                    li {
                        width: calc(33% - 16px);
                        h4 {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .hero-wrapper {
            .content-wrapper {
                margin-top: 20px;
                padding: 0 16px;
                margin-bottom: 30px;
            }
        }
        h2 {
            font-size: 30px;
        }
        .rental-background {
            min-height: 18rem;
            .img-section {
                .img-fluid {
                    object-position: 50% 50%;
                }
            }
        }
        .navbar-brand {
            img {
                width: 180px;
            }
        }
        .rentals-page {
            .background-text {
                h1 {
                    font-size: 2rem;
                }
            }
        }
        .reviews {
            .review-card {
                padding: 2rem 1rem;
            }
        }
        // 1-may
        .main-space {
            padding: 20px 0px;
        }
        .about-service {
            .img-card {
                img {
                    height: 300px;
                    object-fit: scale-down;
                }
                &::after {
                    display: none;
                }
            }

            .mb-3 {
                margin-bottom: 0px !important;
            }
        }
        .swipe-card {
            height: 200px;
            &:hover {
                .icon-div {
                    width: 100px;
                    height: 100px;
                    transform: translateY(-44px);
                    img {
                        width: 80%;
                    }
                }
            }
            .icon-div {
                width: 120px;
                height: 120px;

                img {
                    width: 90%;
                    margin: 0 auto;
                }
            }
        }
        .rental-tents {
            .category-blk {
                &.last-child {
                    h4 {
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .more-inquiry-blk {
            margin-top: 20px;
            gap: 8px;
            .btn-primary {
                padding: 5px 10px;
            }
            h2 {
                font-size: 16px !important;
            }
            .inquiry-icon {
                img {
                    width: 30px;
                }
            }
        }
        .rental-gallery {
            &.only-gallery {
                padding: 20px 0;
            }
        }
        .gallery-card {
            img {
                height: auto;
            }
        }
        .rental-services {
            .services {
                .service-card {
                    h4 {
                        font-size: 16px;
                    }
                    .img-section {
                        height: auto;
                    }
                }
            }
        }
        .rental-tents {
            .img-blk {
                img {
                    height: 132px;
                }
            }
        }
        .price-tag {
            margin: auto;
            margin-top: 1.5rem;
        }
        .rental-tents {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media (min-width: 1400px) {
        .container-xxl {
            max-width: 1360px;
        }
    }
    @media (min-width: 1024px) {
        .navbar-expand-lg {
            .navbar-toggler {
                display: none !important;
            }
        }
    }

    .wedding {
        .overlay-text {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            white-space: nowrap;
        }
        .custom-quote {
            font-size: 22px;
            background: #ffe3cb;
            color: darkslategrey;
            font-style: italic;
            width: fit-content;
            padding-left: 10px;
            font-weight: 500;
            padding-right: 10px;
            margin: auto;
        }
    }
    .package-pricing {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: left;
        gap: 8px;
        border-bottom: 2px solid #fbf4ea;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        min-height: 92px;
        &.custom {
            grid-template-columns: repeat(1, 1fr);
            gap: 2px;
        }
        p {
            font-size: 14px;
            &.price {
                font-size: 22px;
                font-weight: 700;
                color: #cd986c;
            }
        }
    }
    .list-unstyled {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: left;
        gap: 8px;
        padding-left: 2rem;
        margin-bottom: 0;
        li {
            list-style: square;
            &::marker {
                color: #efbc8f;
            }
        }
    }
    .pricing-helper {
        font-size: 14px;
    }
    .wedding-header {
        max-width: 80%;
        margin: auto;
        margin-bottom: 3rem;
        .content {
            flex-basis: 70%;
            text-align: center;
        }
        .flower-img {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .pricing {
        .card {
            border: 5px solid #ffe3cb;
            border-radius: 0;
        }
    }
    .wedding-services {
        .card-wrapper {
            position: relative;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
            h3 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin-bottom: 0;
                color: #fff;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
    .wedding-portfolio {
        .portfolio-img {
            border: 5px solid #ffe3cb;
        }
        .portfolio-content {
            background-color: #fff;
            padding: 2rem;
            margin-left: -15%;
            &.right {
                margin-right: -15%;
                margin-left: 0;
                z-index: 2;
                position: relative;
            }
        }
    }
    .wedding-process {
        .step {
            height: 50px;
            width: 50px;
            font-size: 50px;
            background-color: #ffe3cb;
            border-radius: 50%;
            margin: auto;
            font-weight: 500;
            margin-bottom: 2rem;
        }
    }
    @media screen and (max-width: 768px) {
        .wedding-portfolio {
            .portfolio-content {
                margin-left: 0;
                &.right {
                    margin-right: 0;
                }
            }
        }
        .hero-wrapper {
            .overlay-text {
                white-space: normal !important;
                width: 90%;
                h1 {
                    font-size: 38px;
                }
                .custom-quote {
                    font-size: 18px;
                }
            }
        }
        .wedding-header {
            max-width: 100%;
            margin-bottom: 2rem;
            h2 {
                font-size: 25px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .py-5 {
            padding-top: 2rem !important;
            padding-bottom: 2rem !important;
        }
    }
    @media screen and (max-width: 1024px) {
        .hero-wrapper {
            .overlay-text {
                white-space: normal !important;
                width: 90%;
                h1 {
                    font-size: 38px;
                }
                .custom-quote {
                    font-size: 18px;
                }
            }
        }
        .wedding-header {
            max-width: 100%;
            margin-bottom: 2rem;
            h2 {
                font-size: 25px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .py-5 {
            padding-top: 2rem !important;
            padding-bottom: 2rem !important;
        }
    }
    @media screen and (max-width: 1366px) {
        .list-unstyled {
            li {
                font-size: 15px;
            }
        }
        .wedding-services {
            .card-wrapper {
                h3 {
                    font-size: 22px;
                }
            }
        }
    }
    @media screen and (max-width: 428px) {
        .package-pricing {
            grid-template-columns: repeat(2, 1fr);
            gap: 6px;
            p {
                font-size: 13px;
            }
        }
        .title-helper {
            font-size: 14px;
        }
        .list-unstyled {
            grid-template-columns: repeat(2, 1fr);
            padding-left: 1.2rem;
            li {
                font-size: 13px;
            }
        }
        .br {
            display: flex;
            flex-direction: column;
        }

        .wedding-portfolio {
            .portfolio-content {
                margin-left: 0;
                &.right {
                    margin-right: 0;
                }
            }
        }
        .hero-wrapper {
            .overlay-text {
                white-space: normal !important;
                width: 90%;
                h1 {
                    font-size: 22px;
                }
                .custom-quote {
                    font-size: 14px;
                }
            }
        }
        .wedding-header {
            max-width: 100%;
            margin-bottom: 1rem;
            h2 {
                font-size: 22px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .py-5 {
            padding-top: 1.5rem !important;
            padding-bottom: 1.5rem !important;
        }
        .wedding-services {
            .card-wrapper {
                h3 {
                    font-size: 16px;
                }
            }
        }
        textarea {
            height: 60px;
        }
    }
}

.radio-wrapper {
    color: #21333d;
    background-color: #fff;
    border: 1px solid #eeeeee;
    padding: 12px;
    text-align: left;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    margin: 0 12px 16px 12px;
    gap: 20px;
    width: -webkit-fill-available;
    width: -moz-available;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.form-button {
    margin: 0px 12px;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
}

.modal-header {
    background-color: #ffe3cb;
}

.btn-primary {
    background-color: #ffe3cb;
    border: 0;
    border-radius: 2px;
    color: #21333d;
    font-weight: 500;
    padding: 10px 20px;
    font-size: 14px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #ffe3cb !important;
    color: #21333d !important;
}

.btn-primary:focus {
    box-shadow: none;
}
.btn-primary:active {
    background-color: #ffe3cb;
    color: currentColor;
}

.form-control {
    &.is-invalid {
        padding-right: 35px !important;
    }
}

.form-control::placeholder {
    color: black;
}

#formModal .form-control {
    font-size: 14px !important;
    font-family: "Montserrat", sans-serif;
    color: black;
}

.close {
    padding: 1rem;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.modal-body {
    background-color: #fff !important;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
    }
}

.header-img {
    display: block;
    margin: 0 auto;
}

#message {
    position: fixed;
    background-color: #ffe3cb;
    bottom: 68px;
    right: 5px;
    transition: transform 0.2s;
    transform: translateZ(0);
    will-change: transform;
    backface-visibility: hidden;
    display: none;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 10px;
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        width: 12px;
        height: 12px;
        left: 50%;
        background: inherit;
        transform: translate(-50%, -50%) rotate(45deg);
        z-index: -1;
    }
}

.table {
    td {
        font-size: 14px;
        font-weight: 500;
    }
}

#my-cart-modal {
    &.rental-service {
        background-color: transparent;
    }
    .modal-content {
        border: 0;
    }
}
